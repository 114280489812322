import { useSelector } from "react-redux";
import Logo from "../../../../components/Logo/Logo";
import { getPassengerCategory } from "../../../../utils/getPassengerCategory";
import FlightInfo from "./FlightInfo";
import PassengerInfo from "./PassengerInfo";
import PriceSummary from "./PriceSummary";
import { getCurrencySymbol } from "../../../../features/utils/currency";
import { formatMoney } from "../../../../features/utils/formatMoney";
import moment from "moment";
import TicketFlightInfo from "./TicketFlightInfo";
import PassengerInfoExport from "./PassengerInfoExport";

export default function FlightDoc({data}) {
    const {user} = useSelector(state => state.user.userData);
    const orderData = data?.booking?.flightBooking?.at(0)

    const currency = getCurrencySymbol(
      data?.booking?.flightBooking?.at(0)?.currency,
    )
    return (
      <div className='flex flex-col gap-10 p-6 card' id='flightDoc'>
        <div className='flex flex-col gap-10 p-5'>
          <div className='flex justify-between gap-4'>
            <div className="flex flex-col gap-2">
              {/* <img src={user?.detail?.agencyLogo} alt='' className="w-7 h-7" /> */}
              <h3>{user?.detail?.tradingName}</h3>
              <span>{user?.detail?.address?.businessLocation}</span>
              <span>{user?.detail?.businessPhone || user?.detail?.businessEmail}</span>
            </div>
            {/* <Logo /> */}
            <div>
              Booking Reference
              <div className='text-theme1'>{data?.booking?.bookingId}</div>
            </div>
          </div>
          <hr />
          {orderData?.status === 'Issued' ?
            <div className="text-center flex flex-col gap-2">
                {/* <h2>{user?.detail?.tradingName}</h2> */}
                <h4 className="uppercase">Itinerary</h4>
            </div>
          :null}


          {orderData?.status !== 'Issued' ?
            <div className='bg-primary/10 p-4 rounded-md'>
              Booking status: {orderData?.status} | Your booking expires on : {moment(orderData?.ticketingExpiry)?.format('DD MMM YYYY HH:mm')}
            </div>
          :null}

          <div className='flex gap-2 flex-col'>
            <h5>Passenger Information</h5>
            <div className="">
              {
                orderData?.travelers?.map((traveler,i) => (
                  <PassengerInfoExport key={i} index={i} hideCount
                    label={getPassengerCategory(traveler.birthDate)}
                    traveler={traveler}
                    order={data}
                  />
                ))
              }

                {/* {data?.orderDetail?.travelers &&
                  Array.isArray(data?.orderDetail?.travelers) &&
                  data?.orderDetail?.travelers?.map((traveler) => (
                    <PassengerInfo
                      label={getPassengerCategory(traveler.dateOfBirth)}
                      traveler={traveler}
                      order={data}
                    />
                  ))} */}
                {/* <PassengerInfo label={"Adult"} />
                <PassengerInfo label={"Child"} /> */}
              </div>
              {/* <PriceSummary onPDF={true} data={data} /> */}

            {/* <div className='border border-primary/50 p-4'>
              <PassengerInfo />
            </div> */}
          </div>

          <div className='flex flex-col gap-5'>
            <h5>Flight Details</h5>
            <div className=''>
              <TicketFlightInfo data={data} />
            </div>
          </div>

          {orderData?.status === 'Issued' ? 
            <div className="w-[900px]">
              <span>
                Please prepare/print your itinerary for customs security check. Remember to bring your passport or other identification document with you,
                make sure the information is matched with your itinerary to avoid discrepancy.
              </span>

              <span>
              For international flights, you are suggested to arrive at the airport at least 3-4 hours prior to your departure time to check in and go through
              security. 
              </span>
              <span>
                For connecting flights, please confirm at the counter whether not you can check your luggage through to the final destination when
                you check in.
              </span>
            </div>
          :
            <div className="flex gap-4 justify-between">
              <h5>Trip Total ({currency}):</h5>
              <h5>{formatMoney(data?.booking?.flightBooking?.at(0)?.customerPayable, currency)}</h5>
            </div>
          }

          
        </div>
  
  
        {/* <div className='flex gap-4 flex-col'>
          <h5>Ticket numbers</h5>
          <div className='border border-primary/50 p-4 flex flex-col gap-4'>
            <span>Daniel Atelly: 1</span>
          </div>
        </div> */}
        
      </div>
    )
  }
  
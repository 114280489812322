import moment from "moment";
import { formatMoney } from "../../features/utils/formatMoney";
import { getSupplierName } from "../flight/supplier/getSupplierName";
import { getSupplierClass } from "../../components/flight/FlightOfferDisplay";
import { flightStatusMap } from "../../pages/Orders/OrdersData";
import { alertType } from "../constants";
import Icon from "../../components/HOC/Icon";
import { Link } from "react-router-dom";
import { getStatusName } from "../flight/order/getStatusName";

export function templateFlightOrderData(data) {
    console.log(data);
    const flightData = data?.flightBooking?.at(0);
    // debugger
    let flightType = 'One Way'
    if(flightData?.destinations?.length) {
        flightType = 'Multi City'
        if(flightData?.destinations?.at(-1)?.to === flightData?.destinations?.at(0)?.from)
            flightType = 'Return'
    }
    
    return [
        {
          elements: [
            { type: "title", value: moment(data?.createdAt)?.format('DD-MM-YYYY HH:mm [GMT]Z') },
            { type: "copy", value: flightData?.orderId,
              renderValue: () => <Link target="_blank" to={'/order/flight/ticket/detail/'+data?._id}>{flightData?.orderId}</Link>
            },
            {
              type: "tag",
              value: getSupplierName(flightData?.supplier),
              className: getSupplierClass(flightData?.supplier),
              color: "text-[#BD002A]",
              background: "bg-[#BD002A]/20",
            },
          ],
          value: moment(data?.createdAt)?.format('YYYY-MM-DD HH:mm'),
          width: "md:w-[25%]",
        },
        {
          elements: [
            { type: "title", value: flightType },
            { type: "text", value:
              <div>
                {(flightData?.destinations?.slice(0,1)?.map(obj => `${obj?.from} - ${obj?.to}`)?.join(',') || '')}
                <div>
                  <span className="text-theme1">{flightData?.pnr}</span>
                </div>
                <small>{flightData?.flights?.at(0)?.airlineName}</small>
              </div>
            },
          ],
          width: "md:w-[15%]",
        },
        {
          elements: [
            { type: "title", value: "Travel Time" },
            { type: "text", value: moment(flightData?.flights?.at(0)?.departureDate)?.format('YYYY-MM-DD')+ ' ' },
            { type: "text", value: <span className='text-blue-500'>{flightData?.flights?.at(0)?.departureTime+ ' - ' +flightData?.flights?.at(0)?.arrivalTime}</span> },
          ],
          value: moment(flightData?.flights?.at(0)?.departureDate)?.format('YYYY-MM-DD'),
          width: "md:w-[15%]",
        },
        {
          elements: [
            { type: "title", value: "Passenger" },
            { type: "text", value: flightData?.travelers?.slice(0,1)?.map(obj => `${obj?.firstName || ''} ${obj?.middleName || ''} ${obj?.lastName || ''}`)?.at(0) },
          ],
          width: "md:w-[15%]",
        },
        {
          elements: [
            { type: "title", value: "Agent Price" },
            { type: "text", value: formatMoney(flightData?.payable) },
            {render: () => {
              return (
                <div className="flex flex-col text-xxxs">
                  <span className="text-primary/50">Customer Price</span>
                  <span>{formatMoney(flightData?.customerPayable)}</span>
                </div>
              )
            }}
          ],
          value: flightData?.payable,
          width: "md:w-[15%]",
        },
        {
          elements: [
            { type: "title", value: "Status" },
            { type: "status", value: flightStatusMap(flightData?.status), render: () => {
                const status = flightStatusMap(flightData?.status);
                
                return (
                    <div className={`rounded-md mb-3`}>
                    <span
                    className={`${alertType[flightData?.status?.toLowerCase()]} overflow-hidden text-ellipsis text-xs `}
                    title={status}
                    >
                        {getStatusName(status)}
                    </span>
                    </div>
                )
            }},
            { type: "time", render: () => {
                const diffInSeconds = moment.utc(flightData?.ticketingExpiry).diff(moment.utc(), 'seconds');
                
                // Format the difference in the "5h 10m 5s" format
                const hours = Math.floor(diffInSeconds / 3600);
                const minutes = Math.floor((diffInSeconds % 3600) / 60);
                // const seconds = diffInSeconds % 60;
                const onStage = ['Paid','Pending','Pendingpayment']?.includes(flightData?.status)
            
                return (hours || minutes) > 0 && onStage ? (
                    <div className="flex gap-1 items-center" title="Ticket Expiry Time">
                        <Icon icon='mdi:clock-outline' className='!w-4 !h-4' />
                        {hours || minutes} {hours?'Hrs':'Mins'}
                    </div>
                ):null
            }},
          ],
          value: function () {
            return [
              "Not Paid",
              "Paid",
              "Expired",
              "Awaiting ticketing",
              "Ticket issued",
              "Denied",
              "Canceled",
              "Cancelation Requested",
              "Ticket Canceled",
            ]?.findIndex(val => val === flightStatusMap(flightData?.status))
        
          }(),
          width: "md:w-[15%]",
        },
      ];
    
}